.back {
  display: flex;
  align-items: center;
  gap: 13.3px;
  margin-bottom: 6px;
}

.pageTitle {
  margin-bottom: 0 !important;
}

.arrow {
  margin-bottom: 3px;
}

.image {
  max-width: 119px;
}

.wrapper {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.workedTitle {
  letter-spacing: 0.27px;
  color: #3D3D3D;
  font-size: 15px;
}

.workedHours {
  letter-spacing: 0.27px;
  color: #3D3D3D;
  font-weight: 900;
}

.submittedTitle {
  font-size: 24px;
  margin-left: auto;
}

.icon {
  position: relative;
  margin-bottom: 12px;
}

.verified {
  position: absolute;
  left: 72px;
  top: -25px;
  transform: scale(2.4);
}

.name {
  letter-spacing: 0.27px;
  color: #3D3D3D;
  font-size: 15px;
  font-family: 'Avenir', sans-serif;
  text-align: center;
  font-weight: 500;
  margin-bottom: 6px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.star {
  width: 200px;
}

.profile {
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  flex-grow: 1;
}

.row {
  margin-top: 10px;
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.content {
  display: flex;
  gap: 24px;
}

@media (min-width: 900px) {
  .content {
    max-height: 468px;
  }
}

.row_lg {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 13px;
}

.boldItem {
  font-weight: 900;
  letter-spacing: 0.27px;
  color: #3D3D3D;
}

.cardTaskName {
  letter-spacing: 0.27px;
  color: #3D3D3D;
  font-size: 15px;
  text-align: center;
  margin-bottom: 21px;
}

.submittedReviewIcon {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}

.submittedReviewTitle {
  margin-left: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.link > button {
  width: 100%;
}

.dateCard {
  justify-content: center !important;
  gap: 27px;
}

@media (max-width: 900px) {
  .submittedReviewTitle {
    margin: 0 auto;
    text-align: center;
  }
}

.cardItem {
  letter-spacing: 0.27px;
  color: #3D3D3D;
}

.cardInfoItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
}

.cardInfoImage {
  width: 30px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px #DDDDDD;
  border-radius: 8px;
  object-fit: contain;
  padding: 3px;
}

.cardInfoText {
  font-weight: 900;
}

.cardInfoText::first-letter {
  text-transform: uppercase;
}

.cardInfoText.currency::before {
  content: '$';
}

.cardText {
  letter-spacing: 0.27px;
  color: #3D3D3D;
}

.stars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: end;
  flex-direction: column;
  gap: 5px;
}

.buttonsContainer {
  margin-left: auto;
  display: flex;
  gap: 15px;
  max-width: 352px;
  justify-content: flex-end;
}

.buttonsContainer>button {
  width: 50%;
  padding-left: 0;
  padding-right: 0;
}

.fullWidth>button {
  width: 100%;
}

.review {
  width: 100%;
  resize: none;
  background: none;
  border: none;
  min-height: 98px;
}

.review::placeholder {
  letter-spacing: 0.27px;
  color: #3D3D3D;
  font-size: 15px;
}

.flexCard {
  display: flex;
  justify-content: space-between;
}

.card {
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  padding: 9px;
}

.worked {
  padding: 13px 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  display: flex;
  gap: 17px;
}

.workedText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.interactBtn {
  width: 30px;
}

.interact>button {
  border: none;
  background: none;
}

.interact {
  display: flex;
  gap: 8px;
  align-items: center;
}

.profileLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 900px) {
  .review {
    min-height: 148px;
  }

  .image {
    max-width: 50px;
  }

  .ratingText {
    color: #ACAEAF;
  }

  .verified {
    transform: scale(1);
    left: -30px;
    top: -60px;
  }

  .flexCard {
    flex-direction: row;
  }

  .card {
    border-radius: 8px;
  }

  .buttonsContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
  }

  .buttonsContainer>button {
    width: 100%;
  }
}

.disabledBtn {
  opacity: .5;
}

.centeredRating {
  display: flex;
  justify-content: center;
}

.rateText {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  align-self: center;
  text-align: center;
  color: rgba(36, 37, 61, 0.5);
}

.rate {
  display: flex;
  flex-direction: column;
  align-items: center;
}