.wrapper {
  margin-top: 53px;
}

.userTitle {
  align-self: center;
  font-size: 16px;
  color: #000000;
  opacity: 0.55;
  margin-bottom: 12px;
}

.title {
  font: normal normal 100 35px/40px Avenir;
  letter-spacing: 0.7px;
  color: #3D3D3D;
  margin-bottom: 11px;
}

@media (max-width: 900px) {
  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    letter-spacing: -0.16875px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 38px;
  }
}

.type {
  display: flex;
  align-items: center;
  gap: 17px;
  background: white;
  border-radius: 6px;
  padding: 16px;
  border: none;

}

.typesWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.select {
  margin-top: 19px;
}

.selectTitle {
  margin-bottom: 0;
  margin-bottom: 16px;
}

.alreadyMember {
  font: normal normal 900 18px/26px Avenir;
  letter-spacing: -0.07px;
  color: #3D3D3D;
  margin-right: 11px;
}

.login {
  font: normal normal 900 16px/22px Avenir;
  letter-spacing: 0.16px;
  color: #F27649;
  text-decoration: none;
}

.haveAccount {
  margin-bottom: 8px;
}

.userTypes {
  display: flex;
  margin-bottom: 16px;
  gap: 16px;
}

.button {
  display: flex;
  flex-direction: column;
  max-width: 352px;
  width: 100%;
  background: none;
  border: 1px solid #3E3E3E;
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
}

.blob {
  flex-grow: 1;
  width: 100%;
  padding: 60px;
}

.buttonWrapper {
  max-width: 344px;
  margin-bottom: 54px;
}

.select {
  margin-bottom: 19px;
}

.bottomLink {
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.loginLink {
  text-decoration: none;
  margin-left: 5px;
}

.selectInput {
  padding: '16px 20px'
}