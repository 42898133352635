.bar {
  height: 14px;
  background: #F27649;
  border-radius: 4px;
  transition: .3s;
}

.barWrapper {
  background: #DDDDDD;
  width: 100%;
  border-radius: 4px;
  transition: .3s;
}

.title {
  margin-bottom: 5px;
  text-align: center;
  font: normal normal 900 16px/24px Avenir;
  letter-spacing: 0.38px;
  color: #DDDDDD;
  opacity: 1;
}