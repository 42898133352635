.select-select {
  appearance: none;
  color: white;
  padding: 14px 12px;
  background-color: rgba(242, 118, 73, 1);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  font: normal normal 900 16px/24px Avenir;
  letter-spacing: 0.38px;
  min-height: 48px;
  position: relative;
}

.select-wrapper {
  position: relative;
  min-width: 180px;
}

.select-icon {
  position: absolute;
  right: 16px;
  top: 15px;
  transition: .5s;
}

.select-dropdown {
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  background: white;
  margin-bottom: 3px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  animation: fadein .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.select-dropdown-option {
  color: black;
  transition: .3s;
  padding: 8px 16px;
  font: normal normal medium 16px/24px Avenir;
  font-weight: normal;
}

.select-dropdown-option:first-of-type {
  padding-top: 16px;
}

.select-dropdown-option:last-of-type {
  padding-bottom: 16px;
}

.select-dropdown-option:hover {
  cursor: pointer;
  background-color: rgb(244, 244, 244);
  transition: .3s;
}

.select-icon-bottom {
  position: absolute;
  top: -15px;
  left: 38%;
}