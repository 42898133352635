.passwords {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.password {
  background: #F5F5F5;
  border-radius: 4px;
}

.password>input {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.25;
}