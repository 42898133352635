.column {
  max-width: 328px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.doubleField {
  display: flex;
  gap: 22px;
}

.input,
.input > input {
  background: #f5f5f5 !important;
  border-radius: 4px;
  height: 48px;
  font: normal normal 900 16px/22px Avenir;
}

.input > input::placeholder {
  color: #727272;
}

.interact {
  display: flex;
  gap: 12px;
  margin-top: 80px;
}

.emailWrapper {
  margin-top: 0;
}

.emailWrapper > div > input::placeholder {
  font: normal normal 900 16px/22px Avenir !important;
}

.phone {
  margin-top: 0;
}

.phone > input,
.selectField {
  border-radius: 4px;
  font: normal normal 900 16px/22px Avenir;
  color: #727272;
}

.submitError {
  opacity: 0.5;
}

.interactButton {
  border-radius: 4px;
  text-align: center;
  font-family: Avenir;
  font-size: 15px;
  width: 50%;
  letter-spacing: 0.38px;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 16px 0px;
  font-weight: 500;
}

.content {
  display: flex;
  gap: 39px;
}

@media (max-width: 900px) {
  .content {
    align-items: center;
    flex-direction: column;
    gap: 15px;
  }
  .pageTitle {
    display: none;
  }

  .doubleField {
    flex-direction: column;
  }

  .input {
    width: 100% !important;
  }

  .wrapper {
    margin-top: 25px;
    margin: 25px 15px 0 15px;
    padding-bottom: 50px;
  }
  .column {
    max-width: 100%;
  }

  .interact {
    flex-direction: column;
  }

  .interactButton {
    width: 100%;
  }
}
