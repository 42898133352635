.container {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
}

.settings {
  height: 100%;
  padding-bottom: 40px;
}

.title {
  font-size: 16px;
  margin-bottom: 15px;
}

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.password {
  z-index: 1;
}

.countryButtons {
  display: flex;
  gap: 26px;
  width: 100%;
}

.country {
  max-width: 412px;
  width: 100%;
}

.langButtons {
  display: flex;
  flex-direction: column;
  max-width: 149px;
  gap: 15px;
}

.btn {
  width: 100%;
  padding: 12px 0;
  text-align: left;
  border-radius: 4px;
  padding-left: 16px;
  border: none;
  transition: .3s;
  cursor: pointer;
  font-weight: 500;
  color: rgb(115, 115, 115);
}

.btn:hover {
  opacity: .7;
  transition: .3s;
}

.lang {
  width: 100%;
}

.passwordInput,
.passwordInput>input {
  max-width: 327px;
  width: 100%;
  background: #EFECEF;
  border-radius: 4px;
  max-height: 48px;
}

.settingsBlob {
  z-index: 0;
  max-width: 383px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.oldPassword {
  margin-bottom: 10px;
}


.passwordInput>input::placeholder {
  font-size: 16px;
  opacity: .4;
}

.changePassword {
  display: flex;
  gap: 16px;
  z-index: 10;
}

.changePassword>button {
  height: 48px;
  font-size: 15px;
  max-width: 168px;
  width: 100% !important;
}

@media (max-width: 900px) {
  .wrapper {
    padding: 35px 14px;
    display: flex;
    flex-direction: column;
  }

  .title {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 22px;
  }

  .btn {
    text-align: center;
    max-width: 152px;
    width: 100%;
    color: white;
    padding: 16px 0;
    background: #0F7173;
    box-shadow: 0px 2px 10px #DDDDDD;
    border-radius: 8px;
  }
}

.customSelects>div>select {
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(87, 91, 125, 0.215854);
  border-radius: 8px;
  height: 58px;
}

.customSelects>div>img {
  top: 27px;
}

.customSelects {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 24px;
}

.box {
  padding-top: 38px;
  padding-bottom: 21px;
  padding-right: 20px;
  padding-left: 20px;
  background: white;
  box-shadow: 0px 4px 14px rgba(87, 91, 125, 0.215854);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 37px;
  margin-bottom: 37px;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7A7A7A;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.box:last-child {
  margin-bottom: 0;
}

.centered {
  margin: 0 auto;
  margin-bottom: 46px;
}

.stripeBtn {
  width: 100%;
  background: #F27649;
  box-shadow: 0px 2px 5px 0px #575B7D38;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
}