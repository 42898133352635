.password-field-mobile>input::placeholder {
  color: rgba(0, 0, 0, .5);
  font-family: Avenir;
  font-weight: 400;
  letter-spacing: normal;
  background-color: none;
  font-size: unset;
}

.typeButton {
  text-align: center;
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  letter-spacing: 0.62px;
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 28px 0;
  cursor: pointer;
  transition: .3s;
}

.typeButton:hover {
  opacity: .7;
  transition: .3s;
}

.typeButtons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 251px;
  width: 100%;
  gap: 19px;
}

@media (max-width: 900px) {
  .typeButtons {
    max-width: 100%;
  }
}