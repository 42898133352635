.container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
}

.buttonWrapper {
  max-width: 352px;
  display: flex;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  font-weight: 200;
  letter-spacing: -0.1px;
  color: #3D3D3D;
  opacity: 1;
  text-align: center;
  max-width: 453px;
  margin-bottom: 24px;

}

@media (max-width: 900px) {

  .buttonWrapper {
    margin-bottom: 115px;
  }

  .note {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    text-align: center;

    color: #000000;

    mix-blend-mode: normal;
    opacity: 0.7;
    max-width: 282px;
    margin-left: 8px;
  }

  .title,
  .subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }

  .title {
    margin: 0 auto;
    margin-top: 35px;
    max-width: 296px;
    margin-bottom: 50px;
  }

  .subtitle {
    max-width: 240px;
    margin: 0 auto;
  }
}