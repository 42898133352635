.leftColumn {
  max-width: 352px;
  width: 100%;
}

.input {
  max-height: 48px;
  height: 48px;
  margin: 0 !important;
  margin-bottom: 15px !important;
}

.fieldTitle {
  font: normal normal 900 16px/22px Avenir;
  letter-spacing: 0px;
  color: hsl(0, 0%, 0%);
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .fieldTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
  }
}

.contactInfoTitle {
  margin-top: 15px;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progressBar {
  margin-bottom: 20px;
}

.continueButtonWrapper {
  margin-top: 20px;
}

.fileLoader {
  margin-bottom: 15px;
}

.hidden {
  display: none !important;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.checkbox {
  margin-bottom: 0;
}

.continueBtnWrapper {
  margin-top: 20px;
}