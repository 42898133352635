.profile {
  padding-left: 29px;
  display: block;
  text-decoration: none;
  margin-top: -37px;
}

.circle {
  width: 306.99px;
  height: 306.99px;
  left: -138.19px;
  top: 0;
  top: -22.61px;
  position: absolute;
  background: #000000;
  z-index: -1;
  mix-blend-mode: normal;
  opacity: 0.05;
  border-radius: 50%;
}

.image {
  max-width: 80px;
  border-radius: 50%;
  display: block;
  margin-bottom: 17px;
  border: 2px solid #FFFFFF;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
}

.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.notification {
  align-self: end;
  margin-right: 49px;
}

.notificationImage {
  width: 23px;
}