.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 155px;
  padding-right: 155px;
}

.blob {
  max-width: 117px;
  margin-bottom: 5px;
}

.check {
  margin-bottom: 115px;
}

.title {
  letter-spacing: 1.26px;
  color: #3d3d3d;
  font-size: 42px;
  font-weight: 200;
  max-width: 602px;
  text-align: center;
  margin-bottom: 10px;
}

.nextButton {
  width: 100%;
  background: #f27649;
  margin-bottom: 30px;
  cursor: pointer;
}

.nextButton:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.buttons {
  max-width: 352px;
  width: 100%;
}

.bar {
  margin-bottom: 30px;
}

.appLink {
  background: transparent;
  border: none;
}

.appTitle {
  text-align: center;
  color: #3d3d3d;
  font-weight: 300;
  letter-spacing: 0.6px;
  margin-bottom: 9px;
}

.appLinks {
  display: flex;
  gap: 15px;
}
.appLinks:hover {
  cursor: pointer;
}
@media (max-width: 900px) {
  .wrapper {
    margin-top: 115px;
    padding-left: 0;
    padding-right: 0;
  }

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    max-width: 325px;
    color: #000000;
    margin-bottom: 60px;
  }

  .bar {
    margin-bottom: 60px;
  }

  .appTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: #000000;
  }
}
