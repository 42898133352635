@media (min-width: 900px) {
  .title {
    max-width: 646px;
    text-align: center;
    font: normal normal 300 42px/50px Lato;
    letter-spacing: 1.26px;
    color: #3D3D3D;
    opacity: 1;
    margin-top: 22px;
    margin-bottom: 89px;
  }

  .button {
    padding: 13px 88px;
  }

  .modalBody {
    flex-direction: column;
  }

  .link {
    background: none;
    border: none;
    letter-spacing: 0.16px;
    color: #727272;
    font-family: 'Avenir', sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin-top: 8px;
    cursor: pointer;
  }

  .link:hover {
    opacity: .7;
  }

  div.body {
    padding: 51px 124px 30px;
    border-radius: 4px;
  }
}

@media (max-width: 900px) {
  .blob {
    max-width: 163px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-100%);
    left: 0;
    right: 0;
  }

  .body {
    margin: 0px 15px;
  }

  div.modalBody {
    flex-direction: column;
    gap: 24px;
    box-shadow: none;
    border-radius: 0px 0px 4px 4px;
    padding-bottom: 17px;
  }

  h3.title {
    background: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    border: none;
    border-radius: 0;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
    color: #000000;
    padding-bottom: 20px;
    padding-top: 39px;
  }

  .link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: rgba(0, 0, 0, 0.4);
    background-color: transparent;
    border: none;
  }
}