.button {
  border: none;
  border-radius: 0.2rem;
  padding: 0.6rem;
  cursor: pointer;
  height: 40px;
  color: #ffffff;
  font-weight: bold;
}

.button:hover {
  filter: brightness(1.15);
}
.button:active {
  filter: brightness(1);
}
