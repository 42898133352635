.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.big-green-circle {
  width: 628px;
  height: 628px;
  left: -124px;
  top: 353px;

  position: absolute;

  background: #0F7173;
  border-radius: 50%;
}

.small-light-circle {
  width: 234px;
  height: 234px;
  left: -26px;
  top: 242px;

  position: absolute;

  background: #EBF6E7;
  border-radius: 50%;
}

.big-light-circle {
  position: absolute;
  width: 1000px;
  height: 1000px;
  right: -485px;
  top: 28px;

  background: #EBF6E7;
  border-radius: 50%;
}

.small-green-circle {
  position: absolute;
  width: 442px;
  height: 442px;
  right: -10px;
  top: 546px;

  background: #0F7173;
  border-radius: 50%;
}

@media (max-width: 1400px) {



  .flag-blob {
    position: absolute;
    top: 100px;
  }

  .small-light-circle {
    width: 234px;
    height: 234px;
    left: -26px;
    top: 342px;
  }

  .big-green-circle {
    width: 528px;
    height: 528px;
    left: -124px;
    top: 413px;
  }
}

.notes-blob {
  right: 120px;
  position: absolute;
  bottom: 200px;
}

@media (max-width: 900px) {
  .flag-blob {
    position: absolute;
    top: 185px;
    left: -10px;
    width: 112px;
  }

  .small-green-circle {
    width: 137px;
    height: 137px;
    left: -90px;
  }

  .small-light-circle {
    width: 137px;
    height: 137px;
    left: -80px;
    top: 35%;
  }

  .big-green-circle {
    left: 89.07%;
    right: -62.67%;
    top: 7.02%;
    bottom: 58.99%;
    width: 276px;
    height: 276px;
  }

  .big-light-circle {
    width: 151px;
    height: 151px;
    left: 89.07%;
    top: 70%;
  }

  .notes-blob {
    position: absolute;
    right: -10px;
    width: 100px;
    z-index: -1;
    top: 60%;
  }
}