.file-input-container{
    display: flex;
    justify-content: space-between;
    background: #F5F5F5;
    padding: 2px;
    border-radius: 5px;
}
.browse{
    color: #a5a5a5;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 92px;
    height: 50px;
    background: #ffffff;
    border: 1px #e3e3e3 solid;
}
.input-file-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(117, 117, 117);
    background:#FFFFFF;
    border-radius: 8px;
    font-weight: 500;
    max-height: 50px;
    height: 50px;
    max-width: 50px;
    width: 50px;
    border: 1px #e3e3e3 solid;
}
.file-input-image{
    height: 18.5px;
    width: 15.5px;
}
.file-input-image-uploaded{
   max-width: inherit;
   border-radius: inherit;
}
.image-source-name{
    display: flex;
    justify-content: center;
    align-items: center;
}
.file-input{
    display: none;
}
