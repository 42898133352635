.navName {
  text-align: center;
  line-height: 28px;
  margin-bottom: 22px;
}

.accountWrapper {
  padding-bottom: 163px;
}

.logoutButtonWrapper {
  max-width: 160px;
  margin-top: 18px;
  align-self: start;
  width: 100%;
}

.logoutButton {
  background: none;
  background-color: none;
  background: #0F7173;
  border-radius: 4px;
  outline: none;
  border: none;
}