.header {
  min-width: 160px;
  text-align: start;
  font-family: 'Avenir', sans-serif;
  font-size: 15px;
  letter-spacing: 0.27px;
  color: #3D3D3D;
  padding-bottom: 61px;
}

.table {
  margin-top: 25px;
}

.bodyColumn {
  padding-bottom: 18px;
  text-align: center;
  font-size: 15px;
  font-family: 'Avenir';
  text-align: start;
  letter-spacing: 0.27px;
  color: #3D3D3D;
}

.wrapper {
  max-height: 355px;
  overflow-y: scroll;
  padding: 15px;
  padding-top: 25px;
}

.tableButton {
  background: #0F7173 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 0px 9px;
  font-size: 15px;
  height: 28px;
  color: white;
  outline: none;
  border: none;
  float: right;
}

@media (max-width: 900px) {
  .tableButton {
    max-width: 140px;
    align-self: center;
    border-radius: 8px;
    padding: 16px 7px;
    height: unset;
    font-weight: 600;
    font-size: 12px;
  }

  .wrapper {
    max-height: unset;
    overflow-y: unset;
  }
}

.select {
  padding: 16px;
}

.invoices {
  margin-top: 18px;
}

.invoice {
  background: white;
  padding: 15px;
  box-shadow: 0px 4px 14px rgba(87, 91, 125, 0.22);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 15px;
}

.selectWrapper {
  max-width: 184px;
}

.invoiceLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.13125px;

  color: #444444;
}

.invoiceRow {
  display: flex;
  align-items: end;
  justify-content: space-between;
}