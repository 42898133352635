.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.title {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 66px;
  text-align: center;
  color: #F27649;
  max-width: 478px;
}

.phone {
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .phone {
    width: 205px;
  }

  .title {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;

    color: #F27649;
  }

  .subtitle {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height */


    color: #F27649;
  }
}

.download-banners {
  display: flex;
  gap: 14px;
  margin-bottom: 40px;
}

.subtitle {
  margin-bottom: 17px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  color: #F27649;
}

@media (max-width: 330px) {
  .download-banners {
    flex-direction: column;
  }
}