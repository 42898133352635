* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Avenir;
  src: url("./assets/font/Avenir/AvenirLTStd-Book.otf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Avenir;
  src: url("./assets/font/Avenir/AvenirLTStd-Roman.otf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: Avenir;
  src: url("./assets/font/Avenir/AvenirLTStd-Medium.otf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: Avenir;
  src: url("./assets/font/Avenir/Avenir-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: Avenir;
  src: url("./assets/font/Avenir/AvenirLTStd-Black.otf") format("truetype");
  font-weight: 500;
}

/*---------------------------------------------------------------*/

@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: Poppins;
  src: url("./assets/font/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
}

body {
  font-family: Avenir-heavy;
  font-weight: 300;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
