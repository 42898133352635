.title {
  color: #3D3D3D;
  font-size: 25px;
  font-weight: 300;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  margin-bottom: 33px;
}

.payBtn {
  width: 100%;
  color: white;
  min-height: 48px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  background: none;
  border-radius: 4px;
}

.wrapper {
  padding-right: 60px;
}

.bookings {
  max-height: 365px;
  overflow-y: scroll;
}

.selectWrapper {
  max-width: 184px;
  margin-bottom: 15px;
}

.buttonWrapper {
  max-width: 168px;
  width: 100%;
  z-index: 1;
}

.verified {
  position: absolute;
  left: -9px;
  transform: scale(1.3);
  top: -53px;
}

.bookingProfile {
  position: relative;
  max-width: 63px;
}

.profileImage {
  width: 100%;
  border-radius: 50% !important;
  display: block;
}

.noBookings {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 280px;
  gap: 12px;
  max-width: 438px;
}

.noBookingsTitle {
  letter-spacing: 0.72px;
  color: #3D3D3D;
  font-size: 39px;
  text-align: center;
}

.item {
  display: flex;
  gap: 58px;
}

.booking {
  display: flex;
  align-items: flex-end;
  gap: 18px;
  padding: 15px;
  background: #EFECEF 0% 0% no-repeat padding-box;
  border: 1px solid #F27649;
  border-radius: 4px;
  width: 100%;
}

.bookingInfo {
  margin-top: 6px;
  display: flex;
  align-self: center;
  flex-direction: column;
}

.fixerName {
  letter-spacing: 0.27px;
  color: #3D3D3D;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.date {
  border: 1px solid #F27649;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 13px;
  gap: 13px;
  min-width: 126px;
}

.dateText {
  text-align: center;
  opacity: .7;
}

.fixerTask {
  text-align: center;
  font: normal normal 300 15px/40px Avenir;
  letter-spacing: 0.27px;
  color: #3D3D3D;
  opacity: 0.5;
}

.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 16px;
  max-width: 352px;
  margin-bottom: 19px;
}

.buttons>button {
  height: 48px;
  font-size: 16px;
}

@media (max-width: 900px) {
  .wrapper {
    padding: 15px;
  }

  .bookingProfile {
    max-width: 50px;
  }

  .verified {
    left: -30px;
    top: -60px;
    transform: scale(1);
  }
}

.select {
  margin-bottom: 15px;
}

.mobileItem {
  all: unset;
  background-color: white;
  border: 1px solid #F4BF50;
  box-shadow: 0px 4px 14px rgba(87, 91, 125, 0.22);
  border-radius: 8px;
  padding: 38px 15px 43px 15px;
  margin-bottom: 15px;
  display: block;
}

.top {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 15px;
}

.description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #414141;
}

.divider {
  height: 1px;
  opacity: 0.2;
  border: 1px solid #979797;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 18px;
  margin-bottom: 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

.task {
  width: 38px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px #DDDDDD;
  border-radius: 8px;
  padding: 7px;
}

.status {
  display: flex;
  align-items: center;
  gap: 3px;
}

.tasks {
  gap: 10px;
}

.dateText {
  margin-bottom: 8px;
}

.statusInfo {
  text-transform: capitalize;
}