.fieldTitle {
  margin-top: 20px;
  font: normal normal 900 18px/26px Avenir;
  letter-spacing: -0.07px;
  color: #3D3D3D;
}

@media (max-width: 900px) {
  .fieldTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: #000000;
    margin-top: 10px;
    mix-blend-mode: normal;
    opacity: 0.7;
  }

  .terms {
    margin-bottom: 20px;
  }
}

.buttonWrapper {
  margin-top: 167px;
}

.form {
  margin-bottom: 17px;
  display: flex;
  gap: 16px;
  align-items: start;
}

.selectField {
  background: none;
  background-color: #f5f5f5 !important;
}

.formRow {
  display: flex;
  flex-direction: column;
  min-width: 352px;
}

.error {
  margin-top: 10px;
  color: red;
  transition: .3s;
}

.terms {
  display: flex;
  align-items: center;
  max-width: 352px;
}

.termsText {
  margin-top: 30px;
  margin-left: 5px;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #3D3D3D;
  text-decoration: none;
  letter-spacing: -0.07px;
  color: #3D3D3D;
}

.termsLink {
  color: #3D3D3D;
}

.signUp {
  margin-top: 20px;
}

.lastRow {
  margin-top: 52px;
  align-self: end;
}

.file {
  margin-top: 10px;
}